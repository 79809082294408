import { type AppEnvironment } from '@innerwell/dtos';
import type { PublicRuntimeConfig } from '../types';
import packageJson from '../../../../package.json';

export const getServerPublicRuntimeConfig = () => {
   // this bypasses Next.js' env optimization
   const processEnv = process.env;

   return {
      appVersion: packageJson.version,
      appEnv:
         (processEnv.APP_ENV as AppEnvironment | undefined) ?? 'development',
      apiUrl: processEnv.NEXT_PUBLIC_INNERWELL_API_URL,
      phoneExtensionNumber: processEnv.NEXT_PHONE_EXTENSION_NUMBER,

      nmiUrl: processEnv.NEXT_PUBLIC_NMI_URL,
      nmiDefaultCountry: processEnv.NEXT_PUBLIC_NMI_DEFAULT_COUNTRY,
      collectJsPublicKey: processEnv.NEXT_PUBLIC_NMI_COLLECT_JS_PUBLIC_KEY,
      magentoInsuranceUpsellCatId: processEnv.MAGENTO_INSURANCE_UPSELL_CAT_ID,
      magentoIntakeKetamineUpsellCatId:
         processEnv.MAGENTO_INTAKE_KETAMINE_UPSELL_CAT_ID,
      magentoKetamineProgTherapyAddonTagId:
         processEnv.MAGENTO_KETAMINE_PROG_THERAPY_ADDON_TAG_ID,
      magentoInsuranceKetamineProgTherapyAddonTagId:
         processEnv.MAGENTO_INSURANCE_KETAMINE_PROG_THERAPY_ADDON_TAG_ID,
      magentoInsuranceCatId: processEnv.MAGENTO_INSURANCE_CAT_ID,

      prismicAccessToken: processEnv.NEXT_PUBLIC_PRISMIC_ACCESS_TOKEN,

      zendeskApiKey: processEnv.ZENDESK_API_KEY,
      sendbirdAppId: processEnv.NEXT_PUBLIC_SENDBIRD_APP_ID,

      formsortEnv: processEnv.FORMSORT_ENV,
      saveFormsortUuidToLocalstorage:
         processEnv.SAVE_FORMSORT_UUID_TO_LOCALSTORAGE,

      googleMapsApiKey: processEnv.GOOGLE_MAPS_API_KEY,

      loadConvert: Boolean(processEnv.LOAD_CONVERT),
      loadHotjar: Boolean(processEnv.HOTJAR_ID),
      loadMicrosoftClarity: Boolean(processEnv.LOAD_MICROSOFT_CLARITY),
      loadGtm: Boolean(processEnv.GTM_ID),
      gtmId: processEnv.GTM_ID,
      impactId: processEnv.IMPACT_ID,
      hotjarId: processEnv.HOTJAR_ID,
      customerIoSiteId: processEnv.CUSTOMERIO_SITE_ID,
      customerIoTrackingApiKey: processEnv.CUSTOMERIO_TRACKING_API_KEY,

      sentryEnv: processEnv.SENTRY_ENV || 'development',
      sentryDsn: processEnv.SENTRY_DSN || processEnv.NEXT_PUBLIC_SENTRY_DSN,
   } satisfies PublicRuntimeConfig;
};
